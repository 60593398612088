<template>
  <section class="space-y-12">
    <h4 class="text-xl font-extrabold space-x-4">
      <span>{{ title }}</span>
    </h4>
    <div class="bg-white rounded-xl p-8 space-y-4">
      <div class="flex justify-between">
        <span class="space-x-2 flex items-center">
          <span>Videód hossza:</span>
          <span class="inline-flex font-semibold w-32 bg-gray-100 py-1 px-2 rounded-md">
            {{ $parent.selectedItems.videoLength[0].name }}
          </span>
          <span v-if='functions' class="space-x-2">
            <button type="button" class="small-button" name="button" @click="prevTime()">
              <ph-minus :size="20" />
            </button>
            <button type="button" class="small-button" name="button" @click="nextTime()">
              <ph-plus :size="20" />
            </button>
          </span>
        </span>
        <span>{{ priceFormatter($parent.selectedItems.videoLength[0].price) }}</span>
      </div>
      <hr />
      <div class="flex justify-between">
        <span class="space-x-4 flex items-center">
          <span>Stílusa:</span>
          <span class="inline-flex font-semibold w-24 bg-gray-100 py-1 px-2 rounded-md">
            {{ $parent.selectedItems.videoEditType[0].name }}
          </span>
          <span v-if='functions' class="space-x-2">
            <button v-if="!extra" type="button" class="space-x-2 small-button px-2" name="button" @click="extraAdd()">
              <span><ph-plus :size='20' /></span>
              <span>Extra hozzáadása</span>
            </button>
            <button v-if="extra" type="button" class="space-x-2 small-button px-2" name="button" @click="extraRemove()">
              <span class="icon"><ph-minus :size='20' /></span>
              <span>Extra eltávolítása</span>
            </button>
          </span>
        </span>
        <span>{{ priceFormatter($parent.selectedItems.videoEditType[0].price) }}</span>
      </div>
      <div class="text-sm">
        Az extra csomaggal szerkesztőink további időt töltenek a felvételek fényerejének és hőmérsékletének korrigálásával, a színpaletta finomításával, az átmenetek javításával és a stabilizálás finomhangolásával.
      </div>
      <hr />
      <div class="">
        <div class="flex justify-between">
          <span class="space-x-2 flex items-center">
            <span>Akár <span class="font-semibold">{{ standardAmount }}GB</span> Fotót és videót is feltölthetsz.</span>
          </span>
          <span>{{ priceFormatter($parent.selectedItems.videoSize[0].price) }}</span>
        </div>
        <div class="">
          <span class="space-x-2 flex items-center">
            <span>Extra tárhely:</span>
            <span class="inline-flex font-semibold w-24 bg-gray-100 py-1 px-2 rounded-md">{{ $parent.selectedItems.videoSize[0].name }}</span>
            <span v-if='functions' class="space-x-2">
              <button type="button" class="small-button" name="button" @click="prevAmount()">
                <ph-minus :size="20" />
              </button>
              <button type="button" class="small-button" name="button" @click="nextAmount()">
                <ph-plus :size="20" />
              </button>
            </span>
          </span>
        </div>
      </div>
      <hr class="border-gray-700" />
      <div class="flex justify-between text-lg">
        <span class="font-bold">Összesen</span>
        <span class="font-bold">{{ priceFormatter(getTotal()) }}</span>
      </div>
    </div>
    <div>

      <Checkout />

    </div>
  </section>

</template>

<script>
import Checkout from './checkout'

export default {
  name: 'App',
  components: {
    Checkout
  },
  props: {
    surveyPageNumber: Number,
    title: String
  },
  data: function() {
    return {
      functions: true,
      selectedItems: [],
      standardAmount: null,
      packAmount: [
        10,20,30,40,50
      ],
      extra: Boolean,
      allTime: [],
      allAmount: [],
      allStyle: [],
      selectedTimePosition: null,
      selectedAmountPosition: null,
    }
  },
  methods: {
    priceFormatter(price) {
      var formatter = new Intl.NumberFormat('hu-HU', {
        style: 'currency',
        currency: 'HUF',
        maximumFractionDigits: 0

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(price); /* $2,500.00 */
    },
    getProductPrice(name) {
      const filtered = this.$parent.products.find(el => el.data().name === name)
      return this.priceFormatter(filtered.data().price)
    },
    getTotal() {
      const total = parseInt(this.$parent.selectedItems.videoLength[0].price)+parseInt(this.$parent.selectedItems.videoEditType[0].price)+parseInt(this.$parent.selectedItems.videoSize[0].price)
      return total
    },
    nextTime() {
      const nextProduct = this.selectedTimePosition+1
      this.allTime.forEach((item, i) => {
        if(nextProduct === i) {
          this.$parent.selectedItems.videoLength.splice(0)
          this.$parent.selectedItems.videoLength.push(item)
          this.selectedTimePosition = i
        }
      })
      this.checkAmount()
    },
    prevTime() {
      const nextProduct = this.selectedTimePosition-1
      this.allTime.forEach((item, i) => {
        if(nextProduct === i) {
          this.$parent.selectedItems.videoLength.splice(0)
          this.$parent.selectedItems.videoLength.push(item)
          this.selectedTimePosition = i
        }
      })
      this.checkAmount()
    },
    nextAmount() {
      const nextProduct = this.selectedAmountPosition+1
      this.allAmount.forEach((item, i) => {
        if(nextProduct === i) {
          this.$parent.selectedItems.videoSize.splice(0)
          this.$parent.selectedItems.videoSize.push(item)
          this.selectedAmountPosition = i
        }
      })
    },
    prevAmount() {
      const nextProduct = this.selectedAmountPosition-1
      this.allAmount.forEach((item, i) => {
        if(nextProduct === i) {
          this.$parent.selectedItems.videoSize.splice(0)
          this.$parent.selectedItems.videoSize.push(item)
          this.selectedAmountPosition = i
        }
      })
    },
    extraAdd() {
      this.$parent.selectedItems.videoEditType.splice(0)
      this.$parent.selectedItems.videoEditType.push(this.allStyle[1])
      this.extra = true
    },
    extraRemove() {
      this.$parent.selectedItems.videoEditType.splice(0)
      this.$parent.selectedItems.videoEditType.push(this.allStyle[0])
      this.extra = false
    },
    checkAmount() {
      this.packAmount.forEach((item, i) => {
        if(i === this.selectedTimePosition) {
          this.standardAmount = item
        }
      })
    }
  },
  created() {
    // alapbol a 10gigas tarhely ki van valasztva.
    this.$parent.getFilteredProducts('Amount', this.allAmount)
    this.$parent.getFilteredProducts('Time', this.allTime)
    this.$parent.getFilteredProducts('Style', this.allStyle)
    this.$parent.itemClick(0, this.allAmount, false, this.$parent.selectedItems.videoSize)
    //

    this.allAmount.forEach((item, i) => {
      if(item.name === this.$parent.selectedItems.videoSize[0].name) {
        this.selectedAmountPosition = i
      }
    })

    this.allTime.forEach((item, i) => {
      if(item.name === this.$parent.selectedItems.videoLength[0].name) {
        this.selectedTimePosition = i
      }
    })

    if(this.$parent.selectedItems.videoEditType[0].name === 'Standard') {
      this.extra = false
    }

    this.checkAmount()

  }
}
</script>

<style lang="css" scoped>
</style>
