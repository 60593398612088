<template>
  <section>

    <div class="survey-form-content" v-if="surveyPageNumber == 1">
      <VideoType v-bind:surveyPageNumber="surveyPageNumber" v-bind:title="'Válaszd ki a videód típusát!'" />
    </div>
    <div class="survey-form-content" v-if="surveyPageNumber == 2">
      <VideoCameras v-bind:surveyPageNumber="surveyPageNumber" v-bind:title="'Milyen eszközökkel készültek a felvételek?'" />
    </div>
    <div class="survey-form-content" v-if="surveyPageNumber == 3">
      <VideoEditType v-bind:surveyPageNumber="surveyPageNumber" v-bind:title="'Milyen legyen a szerkesztés stílusa?'" />
    </div>
    <div class="survey-form-content" v-if="surveyPageNumber == 4">
      <VideoLength v-bind:surveyPageNumber="surveyPageNumber" v-bind:title="'Válaszd ki, hogy milyen hosszú legyen a videód!'" />
    </div>
    <div class="survey-form-content" v-if="surveyPageNumber == 5">
      <Summary v-bind:surveyPageNumber="surveyPageNumber" v-bind:title="'Elkészült a személyre szabott ajánlatod.'" />
    </div>

  </section>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/firestore'

import VideoType from '@/views/components/survey_child/01VideoType'
import VideoCameras from '@/views/components/survey_child/02VideoCameras'
import VideoEditType from '@/views/components/survey_child/03VideoEditType'
import VideoLength from '@/views/components/survey_child/04VideoLength'
import Summary from '@/views/components/survey_child/summary'

const db = firebase.firestore()

export default {
  components: {
    VideoType, VideoCameras, VideoEditType, VideoLength, Summary
  },
  data: function () {
    return {
      products: [],
      size: [],
      categories: [],
      surveyPageNumber: 1,
      maximumStep: 5,
      backButton: false,
      selectedItems: {
        videoType: [],
        videoCameras: [],
        videoEditType: [],
        videoLength: [],
        videoSize: [],
        billingDetails: {},
        ordered: false,
      },
    }
  },
  methods: {
    checkout () {
      //console.log(this.selectedItems.videoEditType[0].name);
    },
    itemClick (i, items, multi, type) {
      const data = items

      if (!multi) {
        data.filter(o => o.i !== i).map(o => o.active = false)
        data[i].active = true
        type[0] = data[i]
        //console.log(data);
      } else {
        if (data[i].active) {
          // itt veszi ki
          data[i].active = false

          const selectedItems = type

          selectedItems.forEach((item, index) => {
            if (item.name === data[i].name) {
              type.splice(index, 1)
            }
          })
        } else {
          data[i].active = true
          type.push(data[i])
        }
      }
    },
    filterActiveElement (items, type, multi) {
      const data = type
      let index = 0
      data.forEach((item, i) => {
        if (item.active === true) {
          index++
          items.find(el => el.name === item.name).active = true
        }
      })

      if (index === 0 && items.length > 0) {
        this.itemClick(0, items, multi, type)
      }
    },
    getProducts () {
      try {
        db.collection('products').orderBy('rate', 'asc').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.products.push(doc)
          })
        })
      } catch (err) {
        console.log(err)
      }
      try {
        db.collection('category').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.categories.push(doc)
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    nextStep () {
      // tovabb mehet
      if (this.maximumStep !== this.surveyPageNumber) {
        this.surveyPageNumber += 1
      }

      if (this.surveyPageNumber === 1) {
        this.backButton = false
      } else {
        this.backButton = true
      }
    },
    backStep () {
      if (this.surveyPageNumber !== 1) {
        this.surveyPageNumber -= 1
      }

      if (this.surveyPageNumber === 1) {
        this.backButton = false
      } else {
        this.backButton = true
      }
    },
    getCategoryName (id) {
      const filtered = this.categories.find(el => el.id === id)
      if (filtered) {
        return filtered.data().name
      } else {
        return 'undefined'
      }
    },
    getFilteredProducts (categoryFilter, items) {
      this.products.forEach((item, i) => {
        const categoryName = this.getCategoryName(item.data().category)

        if (categoryName === categoryFilter) {
          const params = {
            id: item.id,
            name: item.data().name,
            desc: item.data().desc,
            price: item.data().price,
            type: item.data().type,
            category: item.data().category,
            rate: item.data().rate
          }
          items.push(params)
        }
      })
    }
  },
  created () {
    this.getProducts()
  }
}
</script>
