<template>
  <div>
    <div class="fixed w-full h-full bg-gradient-to-b from-blue-25 to-yellow-25 background"></div>
    <div class="relative content-wrapper w-full px-16 py-16">
      <section class="max-w-7xl mx-auto space-y-12">
        <div class="grid grid-cols-2 gap-16 py-6">
          <div class="col-auto">
            <div class="space-y-12">

              <h1 class="text-3xl font-extrabold">
                MemoFox Videószerkesztő szolgáltatás megrendelése
              </h1>

              <div class="text-lg font-light">
                A legegyszerűbb módja annak, hogy az emléked egy menő és megosztható videóba mentsd. A fejlett videoszerkesztés és a szakértői történetmesélés lehetővé teszi, hogy egy teljesen testreszabott videóval újraéld az izgalmas kalandodat.
              </div>

              <div class="flex items-center justify-center order-image">
                <img class="w-auto h-auto background" src="@/assets/order-image.png" alt="">
              </div>

            </div>
          </div>
          <div class="col-auto">

            <Survey />

          </div>
        </div>

      </section>
    </div>

    <div class="divide-black divide-y-2">

    </div>

    <section>
      <FourStep />
    </section>

    <section>
      <LatestVideo />
    </section>

  </div>
</template>

<script>
import FourStep from './components/fourStep'
import Survey from './components/survey'
import LatestVideo from './components/latestVideo'

document.title = 'valami'

export default {
  title: 'Worksdot EB21 - Bejelentkezés',
  components: {
    FourStep, Survey, LatestVideo
  }
}

</script>
