<template>
  <section class="space-y-12">
    <h4 class="text-xl font-extrabold space-x-4">
      <span class="text-indigo-600">{{ surveyPageNumber }} / {{ $parent.maximumStep-1 }}</span>
      <span>{{ title }}</span>
    </h4>

    <div class="grid grid-cols-2 gap-2">
      <div v-for='(item, i) in items' :key='i' class="col-auto">
        <div class="border border-transparent border-solid border-4 rounded-lg p-6 bg-white text-sm cursor-pointer h-full space-y-4 font-semibold" @click="$parent.itemClick(i, items, multi, type)" v-bind:class="{'border-indigo-600': item.active, 'is-info': item.info}">
          <span class="">
            <component :is="item.image" class="text-indigo-600"/>
          </span>
          <p class="">
            {{ item.name }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex justify-between">

      <button type="button" class="cursor-not-allowed text-gray-300 inline-flex justify-center items-center py-2 px-4 border text-lg font-normal rounded-lg border-2 border-gray-300" name="button" disabled>
        <ph-caret-left :size="32" weight='regular' />
      </button>

      <button v-if="$parent.maximumStep != surveyPageNumber" @click="$parent.nextStep" type="button" class="inline-flex justify-center items-center cursor-pointer py-2 px-4 border text-lg font-normal rounded-lg focus:outline-none border-2 border-indigo-600 text-indigo-600 hover:border-indigo-800 hover:text-indigo-800 space-x-2" name="button">
        <span>{{ nextButtonText }}</span><ph-caret-right :size="32" weight='regular' />
      </button>

    </div>

  </section>
</template>

<script>
import {markRaw} from "vue"
import { PhCompass, PhBicycle, PhUserFocus, PhCake } from "phosphor-vue";

export default {
  props: {
    surveyPageNumber: Number,
    title: String
  },
  provide: {
   size: 48,
   weight: "duotone",
   mirrored: false
  },
  data () {
    return {
      nextButtonText: 'Következő',
      multi: false,
      type: this.$parent.selectedItems.videoType,
      items: [{
        active: false,
        name: 'Utazás vagy Kaland Videó',
        icon: '',
        image: markRaw(PhCompass)
      }, {
        active: false,
        name: 'Extrém sport vagy Motorsport Videó',
        icon: 'kayaking',
        image: markRaw(PhBicycle)
      }, {
        active: false,
        name: 'Vlog Videó',
        icon: 'video_camera_front',
        image: markRaw(PhUserFocus)
      }, {
        active: false,
        name: 'Esemény, Nászút vagy Esküvői Videó',
        icon: 'event',
        image: markRaw(PhCake)
      }]
    }
  },
  created () {
    this.$parent.filterActiveElement(this.items, this.type, this.multi)
  }
}
</script>

<style lang="css" scoped>
</style>
