<template>
  <button v-if="!checkoutBlock" @click="showCheckout()" class="w-full inline-flex justify-center py-4 px-6 border border-transparent text-lg font-normal rounded-lg text-white bg-indigo-600 hover:bg-indigo-800 focus:outline-none">
    Tovább a fizetéshez
  </button>

  <transition name="custom" enter-active-class="animate__animated animate__slideInRight" leave-active-class="animate__animated animate__slideOutRight">
    <div v-if='checkoutBlock' class="fixed md:w-2/3 lg:w-1/2 xl:w-1/3 h-full bg-white stripe-form top-0 right-0 shadow-2xl p-10 space-y-8">

      <h2 class="font-bold text-2xl flex justify-between items-center">
        <span>Szolgáltatás megrendelése</span>
        <span class="cursor-pointer" @click="hideCheckout()">
          <ph-x :size="40" />
        </span>
      </h2>

      <form class="" @submit.prevent="handleSubmit" method="post">
        <div class="space-y-4">

          <div class="">
            <label for="name" class="text-sm font-bold">Add meg a teljes neved!</label>
            <input id="name" v-model='billingDetails.name' type="text" placeholder="pl. alex" class="w-full px-5 py-3 bg-gray-200 rounded-lg shadow-inner text-md" required>
          </div>

          <div class="">
            <label for="email" class="text-sm font-bold">Add meg az e-mail címed!</label>
            <input id="email" v-model='billingDetails.email' type="email" placeholder="pl. alex@pelda.com" class="w-full px-5 py-3 bg-gray-200 rounded-lg shadow-inner text-md" required>
          </div>

          <div class="">
            <label for="postalCode" class="text-sm font-bold">Add meg a számlázási címed!</label>
            <div class="grid grid-cols-3 gap-2">
              <div class="col-auto">
                <input id="postalCode" v-model='billingDetails.address.postal_code' type="text" placeholder="Irányítószám" class="w-full px-5 py-3 bg-gray-200 rounded-lg shadow-inner text-md" required>
              </div>
              <div class="col-span-2">
                <input id="city" v-model='billingDetails.address.city' type="text" placeholder="Város" class="w-full px-5 py-3 bg-gray-200 rounded-lg shadow-inner text-md" required>
              </div>
              <div class="col-span-3">
                <input id="street" v-model='billingDetails.address.line1' type="text" placeholder="utca, hsz, emelet, ajtó" class="w-full px-5 py-3 bg-gray-200 rounded-lg shadow-inner text-md" required>
              </div>
            </div>
          </div>

          <div class="">
            <label class="text-sm font-bold">Kártyaadatok</label>
            <div class="w-full px-6 py-4 bg-gray-200 rounded-lg shadow-inner text-md">
              <div id="stripe-element-mount-point"></div>
            </div>
          </div>

        </div>

        <div class="absolute bottom-0 right-0 w-full p-4">
          <button v-if='!loading' class="w-full inline-flex justify-center py-4 px-6 border border-transparent text-lg font-normal rounded-lg text-white bg-indigo-600 hover:bg-indigo-800 focus:outline-none">
            {{ loading ? "Loading..." : "Fizetés: "+$parent.priceFormatter($parent.getTotal()) }}
          </button>

          <div v-if='loading' class="w-full inline-flex justify-center items-center space-x-4 py-4 px-6 border border-transparent text-lg font-normal rounded-lg text-white bg-indigo-800">
            <div style="border-top-color:transparent" class="w-6 h-6 border-2 border-white border-solid rounded-full animate-spin"></div>
            <span>Kérlek várj...</span>
          </div>

        </div>

      </form>
    </div>
  </transition>
</template>

<script>
import { useRouter, useRoute } from "vue-router"
import { loadStripe } from '@stripe/stripe-js'

const urlBackend = process.env.VUE_APP_BACKEND_URL
const stripe_pk = process.env.VUE_APP_STRIPE_PK

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#4F46E5",
      color: "black",
      fontSize: "16px",
      fontFamily: '"Poppins", sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#9CA3AF"
      }
    },
    invalid: {
      color: "#DC2626",
      ":focus": {
        color: "#303238"
      }
    }
  }
}

export default {
  data() {
    return {
      devices: [],
      checkoutBlock: false,
      router: useRouter(),
      stripe: null,
      elements: null,
      loading: true,
      billingDetails: {
        name: null,
        email: null,
        address: {
          city: '',
          line1: '',
          state: 'state',
          postal_code: ''
        }
      }
    }
  },
  methods: {

    hideCheckout() {
      this.checkoutBlock = false
      this.$parent.functions = true
    },

    showCheckout() {
      this.checkoutBlock = true
      this.$parent.functions = false
      this.mountCheckout()
    },

    async mountCheckout() {

        const ELEMENT_TYPE = "card"
        this.stripe = await loadStripe(stripe_pk)
        this.elements = this.stripe.elements()
        const element = this.elements.create(ELEMENT_TYPE, CARD_ELEMENT_OPTIONS)
        element.mount("#stripe-element-mount-point")
        this.loading = false

    },

    async handleSubmit(event) {

      if(this.loading) return
      this.loading = true

      let data = Object.entries(this.$parent.$parent.selectedItems)
      let cart = []

      // localstorage - ha kell
      //this.$store.commit('addToCart', data)

      data.forEach((item, i) => {
        const element = item[1][0]

        if(element != undefined && element.id) {
          cart.push({ [element.id] : 1 })
        }

      })

      cart = Object.assign({}, ...cart)

      const {name, email, address, city, state, zip} = Object.fromEntries(
        new FormData(event.target)
      )

      const billingDetails = {
        name: this.billingDetails.name,
        email: this.billingDetails.email,
        address: {
          city: this.billingDetails.address.city,
          line1: this.billingDetails.address.line1,
          state: 'state',
          postal_code: this.billingDetails.address.postal_code
        }
      }

      const cardElement = this.elements.getElement("card")
      const totalAmount = this.$parent.getTotal() * 100

      const orderData = {
        name: null,
        email: null,
        price: null,
        address: null,
        stripe_id: null,
        service: {
          type: null,
          style: null,
          time: null,
          storage: null,
          devices: null
        }
      }

      // adatok kuldesre es mentesre.
      orderData.name = this.billingDetails.name
      orderData.email = this.billingDetails.email
      orderData.price = this.$parent.priceFormatter(this.$parent.getTotal())
      orderData.address = billingDetails.address.postal_code + ' ' + billingDetails.address.city + ', ' + billingDetails.address.line1
      orderData.service = {
        type: this.$parent.$parent.selectedItems.videoType[0].name,
        style: this.$parent.$parent.selectedItems.videoEditType[0].name,
        time: this.$parent.$parent.selectedItems.videoLength[0].name,
        storage: this.$parent.standardAmount + this.$parent.$parent.selectedItems.videoSize[0].name,
        devices: this.devices
      }

      try {
        const response = await fetch(urlBackend + "checkout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ cart, orderData })
        })

        const { secret, orderId } = await response.json()

        const paymentMethodReq = await this.stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: billingDetails
        })

        const { error } = await this.stripe.confirmCardPayment(secret, {
          payment_method: paymentMethodReq.paymentMethod.id
        })

        //kesz
        this.router.push("/success")

      } catch (error) {

        console.log("ERROR: ", error)
        this.loading = false

      }

    },

    orderSave() {

    },

    userSave() {

    }

  },
  created() {

    for (var i = 0; i < this.$parent.$parent.selectedItems.videoCameras.length; i++) {
      this.devices.push(this.$parent.$parent.selectedItems.videoCameras[i].name)
    }

    this.devices = this.devices.join(', ')

  }
}
</script>

<style lang="css" scoped>
.stripe-form {
  z-index: 999;
}
</style>
