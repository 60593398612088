<template>

  <section class="space-y-12">
    <h4 class="text-xl font-extrabold space-x-4">
      <span class="text-indigo-600">{{ surveyPageNumber }} / {{ $parent.maximumStep-1 }}</span>
      <span>{{ title }}</span>
    </h4>

    <div class="grid grid-cols-2 gap-2">
      <div v-for='(item, i) in items' :key='i' class="col-auto">
        <div class="border border-transparent border-solid border-4 rounded-lg p-6 bg-white text-sm cursor-pointer h-full space-y-4 font-semibold" @click="$parent.itemClick(i, items, multi, type)" v-bind:class="{'border-indigo-600': item.active, 'is-info': item.info}">
          <span class="">
            <component :is="item.image" class="text-indigo-600"/>
          </span>
          <p class="">
            {{ item.name }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex justify-between">

      <button type="button" @click="$parent.backStep" class="cursor-pointer text-indigo-600 inline-flex justify-center items-center py-2 px-4 border text-lg font-normal rounded-lg border-2 border-indigo-600" name="button">
        <ph-caret-left :size="32" weight='regular' />
      </button>

      <button v-if="$parent.maximumStep != surveyPageNumber" @click="$parent.nextStep" type="button" class="inline-flex justify-center items-center cursor-pointer py-2 px-4 border text-lg font-normal rounded-lg focus:outline-none border-2 border-indigo-600 text-indigo-600 hover:border-indigo-800 hover:text-indigo-800 space-x-2" name="button">
        <span>{{ nextButtonText }}</span><ph-caret-right :size="32" weight='regular' />
      </button>

    </div>

  </section>

</template>

<script>
import { markRaw } from "vue"
import { PhPaperPlaneTilt, PhDeviceMobileCamera, PhImageSquare, PhInstagramLogo, PhStopCircle, PhCamera } from "phosphor-vue";

export default {
  props: {
    surveyPageNumber: Number,
    title: String
  },
  provide: {
   size: 48,
   weight: "duotone",
   mirrored: false
  },
  data () {
    return {
      nextButtonText: 'Következő',
      multi: true,
      type: this.$parent.selectedItems.videoCameras,
      items: [{
        active: false,
        name: 'Drone (DJI, Parrot, Autel... stb.)',
        image: markRaw(PhPaperPlaneTilt)
      }, {
        active: false,
        name: 'Okostelefon (iPhone, Samsung... stb.)',
        image: markRaw(PhDeviceMobileCamera)
      }, {
        active: false,
        name: 'Képek',
        image: markRaw(PhImageSquare)
      }, {
        active: false,
        name: 'Akció kamera (GoPro, Sony... stb.)',
        image: markRaw(PhInstagramLogo)
      }, {
        active: false,
        name: '360 Camera (GoPro Max, Insta 360... stb.)',
        image: markRaw(PhStopCircle)
      }, {
        active: false,
        name: 'DSLR (Sony, Cannon, Nikon... stb.)',
        image: markRaw(PhCamera)
      }]
    }
  },
  created () {
    this.$parent.filterActiveElement(this.items, this.type, this.multi)
  }
}
</script>

<style lang="css" scoped>
</style>
